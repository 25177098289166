/**
 * Página Admin Product Update:
 * 
 * acá se modifica el detalle de un producto
 * 
 * @module AdminProductUpdatejsx
 */


import { useState, useEffect } from "react";
import axios from "axios";

import { AdminSidebar } from "../../components";
import { useNavigate } from "react-router-dom";
import Mensaje from "../../components/Modales/Mensaje";
//import { Editor } from '@tinymce/tinymce-react';
import React, { useRef } from 'react';
import $ from 'jquery';

import "../../assets/summernote/summernote-lite.min.css";
import "../../assets/summernote/summernote-lite.min.js";
import "../../assets/summernote/font/summernote.eot";
import "../../assets/summernote/font/summernote.ttf";
import "../../assets/summernote/font/summernote.woff2";
import "../../assets/summernote/font/summernote.woff";

const ProductUpdate = (props) => {

  /**
* @constant
* @name mostrarInputs
* @type {boolean}
* 
* */
  const [mostrarInputs, setMostrarInputs] = useState(false);

  /**
* @constant
* @name marcas
* @type {string}
* 
* */
  const [marcas, setMarcas] = useState();

  /**
* @constant
* @name tallas
* @type {string}
* 
* */
  const [tallas, setTallas] = useState();


  /**
* @constant
* @name skuCode
* @type {string}
* 
* */
  const [skuCode, setSkuCode] = useState("");
  const accessToekn = sessionStorage.getItem("access");

  /**
* @constant
* @name check_mercado_libre
* @type {boolean}
* 
* */
  const [check_mercado_libre, setCheck_mercado_libre] = useState(false);

  /**
* @constant
* @name check_paris
* @type {boolean}
* 
* */
  const [check_paris, setCheck_paris] = useState(false);

  /**
* @constant
* @name check_ripley
* @type {boolean}
* 
* */
  const [check_ripley, setCheck_ripley] = useState(false);

  /**
* @constant
* @name mercadolibre_categorias
* @type {Array}
* 
* */
  const [mercadolibre_categorias, setMercadolibre_categorias] = useState([]);


  /**
* @constant
* @name selected_mercadolibre_categoria
* @type {string}
* 
* */
  const [selected_mercadolibre_categoria, setSelected_mercadolibre_categoria] = useState("");

  /**
* @constant
* @name paris_familias
* @type {Array}
* 
* */
  const [paris_familias, setParis_familias] = useState([]);

  /**
* @constant
* @name selected_paris_familias
* @type {string}
* 
* */
  const [selected_paris_familias, setSelected_paris_familias] = useState("");

  /**
* @constant
* @name paris_categorias
* @type {Array}
* 
* */
  const [paris_categorias, setParis_categorias] = useState([]);

  /**
* @constant
* @name selected_paris_categoria
* @type {string}
* 
* */
  const [selected_paris_categoria, setSelected_paris_categoria] = useState("");

  /**
* @constant
* @name ripley_categorias
* @type {Array}
* 
* */
  const [ripley_categorias, setRipley_categorias] = useState([]);

  /**
* @constant
* @name selected_ripley_categoria
* @type {string}
* 
* */
  const [selected_ripley_categoria, setSelected_ripley_categoria] = useState("");

  /**
* @constant
* @name inputs
* @type {Array}
* 
* */
  const [inputs, setInputs] = useState([]);


  /**
* @constant
* @name inputs_paris
* @type {Array}
* 
* */
  const [inputs_paris, setInputs_paris] = useState([]);

  /**
* @constant
* @name inputs_ripley
* @type {Array}
* 
* */
  const [inputs_ripley, setInputs_ripley] = useState([]);


  /**
* @constant
* @name mensaje
* @type {string}
* 
* */
  const [mensaje, setMensaje] = useState("");

  /**
* @constant
* @name productName
* @type {string}
* 
* */

  const [productName, setProductName] = useState("");

  /**
* @constant
* @name sku
* @type {string}
* 
* */
  const [sku, setSku] = useState("");

  /**
* @constant
* @name brand
* @type {string}
* 
* */
  const [brand, setBrand] = useState("");

  /**
* @constant
* @name price
* @type {string}
* 
* */
  const [price, setPrice] = useState("");


  /**
* @constant
* @name discountPrice
* @type {string}
* 
* */
  const [discountPrice, setDiscountPrice] = useState("");


  /**
* @constant
* @name stock
* @type {string}
* 
* */
  const [stock, setStock] = useState("");

  /**
* @constant
* @name slug
* @type {string}
* 
* */
  const [slug, setSlug] = useState("");

  /**
* @constant
* @name selectedSize
* @type {string}
* 
* */
  const [selectedSize, setSelectedSize] = useState("");

  /**
* @constant
* @name upc
* @type {string}
* 
* */
  const [upc, setUpc] = useState("");

  /**
* @constant
* @name pn
* @type {string}
* 
* */
  const [pn, setPn] = useState("");

  /**
* @constant
* @name msg
* @type {string}
* 
* */

  const [msg, setMsg] = useState("");


  /**
* @constant
* @name tipo
* @type {string}
* 
* */
  const [tipo, setTipo] = useState("");

  /**
* @constant
* @name data_caracteritica
* @type {string}
* 
* */
  const [data_caracteritica, setData_caracteritica] = useState("");

  /**
* @constant
* @name data_short
* @type {string}
* 
* */
  const [data_short, setData_short] = useState("");

  /**
* @constant
* @name data_descripcion
* @type {string}
* 
* */
  const [data_descripcion, setData_descripcion] = useState("");


  /**
* @constant
* @name originales
* @type {Array}
* 
* */
  const [originales, setOriginales] = useState([]);


  /**
* @constant
* @name foto1
* @type {?string}
* 
* */
  const [foto1, setFoto1] = useState(null);


  /**
* @constant
* @name foto2
* @type {?string}
* 
* */
  const [foto2, setFoto2] = useState(null);


  /**
* @constant
* @name foto3
* @type {?string}
* 
* */
  const [foto3, setFoto3] = useState(null);


  /**
* @constant
* @name foto4
* @type {?string}
* 
* */
  const [foto4, setFoto4] = useState(null);

  /**
* @constant
* @name foto5
* @type {?string}
* 
* */
  const [foto5, setFoto5] = useState(null);

  /**
* @constant
* @name foto6
* @type {?string}
* 
* */
  const [foto6, setFoto6] = useState(null);

  const [categoriaPadre, setCategoriaPadre] = useState([]);
  const [categoriaHija, setCategoriaHija] = useState([]);
  const [selectedCategoriaPadre, setSelectedCategoriaPadre] = useState("");
  const [selectedCategoriaHija, setSelectedCategoriaHija] = useState("");
  const catPadreO = useRef("");
  const catHijaO = useRef("");
  const [normalPrice, setNormalPrice] = useState("");

  const handle_check_mercado_libre = () => {
    setCheck_mercado_libre(!check_mercado_libre);
  };

  const handle_check_paris = () => {
    setCheck_paris(!check_paris);
  };

  const handle_check_ripley = () => {
    setCheck_ripley(!check_ripley);
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/admin/categoria/get_all_categoria`)
      .then((response) => {
        setCategoriaPadre(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {

    /**
* @function
* Petición HTTP que obtiene todas las categorias hijas por padre seleccionado
* @returns {Array}
*/

    if (selectedCategoriaPadre !== "") {
      axios
        .get(process.env.REACT_APP_BACK_URL + `/api/admin/categoria/get_all_categoria_hija_by_padre/${selectedCategoriaPadre}`)
        .then((response) => {
          setCategoriaHija(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setCategoriaHija([]);
    }
  }, [selectedCategoriaPadre]);

  useEffect(() => {



    /**
  * @function
  * Petición HTTP que obtiene todas las tallas de los productos
  * @returns {Array}
  */

    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/admin/listar_todas_tallas_productos`)
      .then((response) => {
        setTallas(response.data);
      })
      .catch((error) => {
        console.log(error);
      });


    /**
* @function
* Petición HTTP que obtiene todas las marcas
* @returns {Array}
*/
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/admin/marca/get_all_marcas`)
      .then((response) => {
        setMarcas(response.data);
      })
      .catch((error) => {
        console.log(error);
      });


    /**
* @function
* Petición HTTP que obtiene todas las familias
* @returns {Array}
*/
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/admin/paris/get_familias`)
      .then((response) => {
        setParis_familias(response.data);
      })
      .catch((error) => {
        console.log(error);
      });


    /**
* @function
* Petición HTTP que obtiene desde ripley las categorias
* @returns {Array}
*/
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/admin/ripley/get_categorias`)
      .then((response) => {
        setRipley_categorias(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {

    /**
* @function
* Petición HTTP que obtiene paris los atributos de la familia seleccionada
* @param {string} selected_paris_familias Familia de paris
* @returns {Array}
*/

    if (selected_paris_familias !== "") {
      setInputs_paris([]);
      axios
        .get(process.env.REACT_APP_BACK_URL + `/api/admin/paris/get_atributos_by_familia/${selected_paris_familias}`)
        .then((response) => {
          return response.data
        })
        .then((input) => {
          setInputs_paris(input)
          for (let i = 0; i < inputs_paris.length; i++) {
            const values = [...inputs_paris];
            values[i].value = "";
            setInputs_paris(inputs_paris);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setInputs_paris([]);
    }
  }, [selected_paris_familias]);



  /**
* @function
* Petición HTTP que obtiene el producto por SKU
* @param {string} skuCode Sku del producto a buscar
*/

  useEffect(() => {
    if (inputs_paris.length !== 0) {
      axios
        .get(
          process.env.REACT_APP_BACK_URL + `/api/admin/paris/get_producto_by_sku/${skuCode}`
        )
        .then((response2) => {

          for (let i = 0; i < inputs_paris.length; i++) {
            for (let j = 0; j < response2.data.results[0].variants[0].attributes.length; j++) {
              if (response2.data.results[0].variants[0].attributes[j].id == inputs_paris[i].attribute_id) {

                const values = [...inputs_paris];
                values[i].value = response2.data.results[0].variants[0].attributes[j].optionId + "_" + inputs_paris[i].type;
                setInputs_paris(inputs_paris);

              }
            }
            for (let j = 0; j < response2.data.results[0].attributes.length; j++) {
              if (response2.data.results[0].attributes[j].id == inputs_paris[i].attribute_id) {

                const values = [...inputs_paris];
                values[i].value = response2.data.results[0].attributes[j].optionId + "_" + inputs_paris[i].type;
                setInputs_paris(inputs_paris);
              }
            }
          }

          setMostrarInputs(true);


        })
        .catch((error) => {
          console.log(error);
        });
    }

  }, [inputs_paris]);



  /**
* @function
* Petición HTTP que obtiene las categorias por codigo
* @param {string} selected_paris_familias familia seleccionada
* @returns {Array}
*/
  useEffect(() => {
    if (selected_paris_familias !== "") {
      setParis_categorias([]);
      axios
        .get(process.env.REACT_APP_BACK_URL + `/api/admin/paris/get_categorias_by_code/${selected_paris_familias}`)
        .then((response) => {
          return response.data
        })
        .then((input) => {
          setParis_categorias(input)
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setParis_categorias([]);
    }
  }, [selected_paris_familias]);




  const handleInputChange = (index, event) => {
    const values = [...inputs];
    values[index].value = event.target.value;
    setInputs(values);
  };

  const handleInputChangeParis = (index, event) => {
    const values = [...inputs_paris];
    values[index].value = event.target.value;
    setInputs_paris(inputs_paris);
  };

  const handleInputChangerRipley = (index, event) => {
    const values = [...inputs_ripley];
    values[index].value = event.target.value;
  };


  const setFotosNuevas = (id, foto) => {
    let img = document.getElementById("fotoMostrar" + id);
    let input = document.getElementById("foto" + id);

    if (foto != null && foto != "") {
      img.src = URL.createObjectURL(foto);
      if (id == 1) {
        setFoto1(foto);
      }
      if (id == 2) {
        setFoto2(foto);
      }
      if (id == 3) {
        setFoto3(foto);
      }
      if (id == 4) {
        setFoto4(foto);
      }
      if (id == 5) {
        setFoto5(foto);
      }
      if (id == 6) {
        setFoto6(foto);
      }
    }
  };



  $('#summernote').summernote({
    height: 100,
    toolbar: [
      ['style', ['style']],
      ['font', ['bold', 'underline', 'clear', 'italic']],
      ['color', ['color']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['table', ['table']],
      ['insert', ['link', 'picture', 'video']],
      ['view', ['fullscreen', 'codeview', 'help']]
    ],
    callbacks: {
      onKeydown: function (e) {
        var t = e.currentTarget.innerText;
        if (t.trim().length >= 1000) {
          //delete keys, arrow keys, copy, cut, select all
          if (e.keyCode != 8 && !(e.keyCode >= 37 && e.keyCode <= 40) && e.keyCode != 46 && !(e.keyCode == 88 && e.ctrlKey) && !(e.keyCode == 67 && e.ctrlKey) && !(e.keyCode == 65 && e.ctrlKey))
            e.preventDefault();
        }
      },
      onKeyup: function (e) {
        var t = e.currentTarget.innerText;
        // $('#maxContentPost').text(400 - t.trim().length);
      },
      onPaste: function (e) {
        var t = e.currentTarget.innerText;
        var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
        e.preventDefault();
        var maxPaste = bufferText.length;
        if (t.length + bufferText.length > 1000) {
          maxPaste = 1000 - t.length;
        }
        if (maxPaste > 0) {
          document.execCommand('insertText', false, bufferText.substring(0, maxPaste));
        }
        // $('#maxContentPost').text(400 - t.length);
      },
      onChange: function (e) {
        setData_caracteritica(e);
      },
      onInit: function (e) {
        // $('#summernote').summernote('code', data_caracteritica);
        // console.log(data_caracteritica);

        // $('#summernote').summernote('codeview.activate');

      }
    }
  })


  $('#summernote2').summernote({
    height: 100,
    toolbar: [
      ['style', ['style']],
      ['font', ['bold', 'underline', 'clear', 'italic']],
      ['color', ['color']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['table', ['table']],
      ['insert', ['link', 'picture', 'video']],
      ['view', ['fullscreen', 'codeview', 'help']]
    ],
    callbacks: {
      onKeydown: function (e) {
        var t = e.currentTarget.innerText;
        if (t.trim().length >= 50000) {
          //delete keys, arrow keys, copy, cut, select all
          if (e.keyCode != 8 && !(e.keyCode >= 37 && e.keyCode <= 40) && e.keyCode != 46 && !(e.keyCode == 88 && e.ctrlKey) && !(e.keyCode == 67 && e.ctrlKey) && !(e.keyCode == 65 && e.ctrlKey))
            e.preventDefault();
        }
      },
      onKeyup: function (e) {
        var t = e.currentTarget.innerText;
        // $('#maxContentPost').text(400 - t.trim().length);
      },
      onPaste: function (e) {
        var t = e.currentTarget.innerText;
        var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
        e.preventDefault();
        var maxPaste = bufferText.length;
        if (t.length + bufferText.length > 50000) {
          maxPaste = 50000 - t.length;
        }
        if (maxPaste > 0) {
          document.execCommand('insertText', false, bufferText.substring(0, maxPaste));
        }
        // $('#maxContentPost').text(400 - t.length);
      },
      onChange: function (e) {
        setData_descripcion(e);
      }
    }
  });

  $('#summernote3').summernote({
    height: 100,
    toolbar: [
      ['style', ['style']],
      ['font', ['bold', 'underline', 'clear', 'italic']],
      ['color', ['color']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['table', ['table']],
      ['insert', ['link', 'picture', 'video']],
      ['view', ['fullscreen', 'codeview', 'help']]
    ],
    callbacks: {
      onKeydown: function (e) {
        var t = e.currentTarget.innerText;
        if (t.trim().length >= 10000) {
          //delete keys, arrow keys, copy, cut, select all
          if (e.keyCode != 8 && !(e.keyCode >= 37 && e.keyCode <= 40) && e.keyCode != 46 && !(e.keyCode == 88 && e.ctrlKey) && !(e.keyCode == 67 && e.ctrlKey) && !(e.keyCode == 65 && e.ctrlKey))
            e.preventDefault();
        }
      },
      onKeyup: function (e) {
        var t = e.currentTarget.innerText;
        // $('#maxContentPost').text(400 - t.trim().length);
      },
      onPaste: function (e) {
        var t = e.currentTarget.innerText;
        var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
        e.preventDefault();
        var maxPaste = bufferText.length;
        if (t.length + bufferText.length > 10000) {
          maxPaste = 10000 - t.length;
        }
        if (maxPaste > 0) {
          document.execCommand('insertText', false, bufferText.substring(0, maxPaste));
        }
        // $('#maxContentPost').text(400 - t.length);
      },
      onChange: function (e) {
        setData_short(e);
      }
    }
  });


  /**
* @function
* Petición HTTP que modifica los datos del productos buscado por SKU
* @param {Object} formData formData con todos los datos
* @returns {Array}
*/
  const handleSubmit = (e) => {
    e.preventDefault();
    setMensaje("Editando producto-alert");
    setMsg("");
    setTipo(0);
    let des = parseInt(discountPrice);
    let tac = parseInt(normalPrice);
    let dif = (tac - des) * 100 / tac;
    if (dif <= 10) {
      setMsg("El precio tachado debe ser más de 10% superior al precio descuento");
      setMensaje("El precio tachado debe ser más de 10% superior al precio descuento-rojo");
      return false;
    }

    const formData = new FormData();
    formData.append("sku", sku);
    formData.append("name", productName);
    formData.append("description", data_descripcion.replaceAll("&lt;", "<").replaceAll("&gt;", ">"));
    formData.append("short", data_short.replaceAll("&lt;", "<").replaceAll("&gt;", ">"));
    formData.append("marcas_id", brand);
    formData.append("precio_descuento", discountPrice);
    formData.append("stock", stock);
    formData.append("slug", slug);
    formData.append("caracteristicas", data_caracteritica.replaceAll("&lt;", "<").replaceAll("&gt;", ">"));
    formData.append("upc", upc);
    formData.append("pn", pn);
    formData.append("talla_producto", selectedSize);
    formData.append("catPO", catPadreO.current);
    formData.append("catHO", catHijaO.current);
    formData.append("catPN", selectedCategoriaPadre);
    formData.append("catHN", selectedCategoriaHija);
    formData.append("precio_tachado", normalPrice);
    formData.append("skuOriginal", skuCode);

    if (foto1 != null) {
      formData.append("foto_1", foto1);
    }
    if (foto2 != null) {
      formData.append("foto_2", foto2);
    }
    if (foto3 != null) {
      formData.append("foto_3", foto3);
    }
    if (foto4 != null) {
      formData.append("foto_4", foto4);
    }
    if (foto5 != null) {
      formData.append("foto_5", foto5);
    }
    if (foto6 != null) {
      formData.append("foto_6", foto6);
    }

    for (var pair of formData.entries()) {
      //console.log(pair[0] + ', ' + pair[1]);
    }


    axios
      .post(
        process.env.REACT_APP_BACK_URL + "/api/admin/producto/update_producto",
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToekn}`,
          },
        }
      )
      .then((response) => {
        setMsg("Producto editado correctamente");
        setMensaje("Producto editado correctamente-verde");
        if (check_paris) {
          const jsonFormattedDataParis = {
            nombre: productName,
            sku: sku,
            familia: selected_paris_familias,
            category: selected_paris_categoria,
            precio: parseInt(price),
            foto_1: process.env.REACT_APP_FRONT_URL + response.data.fotos[0].ruta,
          };

          const dataToSendParis = {
            valores: inputs_paris.map((values) => {
              return { name: values.attribute_id, value: values.value };
            }),
          };

          dataToSendParis.valores.forEach((obj) => {
            jsonFormattedDataParis[obj.name] = obj.value;
          });

          axios
            .post(
              process.env.REACT_APP_BACK_URL + "/api/admin/paris/update_producto",
              jsonFormattedDataParis, {}
            )
            .then((response) => {
            })
            .catch((error) => {
              console.error(error);
            });
        }
        setTipo(1);

        setTimeout(() => {
          setMsg("");
          setTipo(0);
        }, 5000);

        setSkuCode("");
        setProductName("");
        setSku("");
        setBrand("");
        setPrice("");
        setDiscountPrice("");
        setStock("");
        setSlug("");
        setSelectedSize("");
        setUpc("");
        setPn("");
        setData_descripcion("");
        setData_caracteritica("");
        setData_short("");
        setSelectedCategoriaHija(0);
        setSelectedCategoriaPadre(0);
        setNormalPrice("");
        $('#summernote').summernote('code', '');
        $('#summernote2').summernote('code', '');
        $('#summernote3').summernote('code', '');
        for (let i = 0; i < 6; i++) {
          let img = document.getElementById("fotoMostrar" + (i + 1));
          let foto = document.getElementById("foto" + (i + 1));
          img.src = "";
          foto.value = "";
        }
        setFoto1(null);
        setFoto2(null);
        setFoto3(null);
        setFoto4(null);
        setFoto5(null);
        setFoto6(null);
      })
      .catch((error) => {
        console.error(error);
        // Verifica si error.response.data.message existe, de lo contrario usa el mensaje por defecto
        const errorMessage = error?.response?.data?.message
          ? error.response.data.message + "-rojo"
          : "No se pudo agregar el producto, intente nuevamente-rojo";
        setMensaje(errorMessage);
        setTipo(2);

        setTimeout(() => {
          setMsg("");
          setTipo(0);
        }, 5000);
      });
  };

  const handleInputChangeSku = (event) => {
    setSkuCode(event.target.value);
  };


  /**
* @function
* Petición HTTP que busca un producto por SKU
* @param {string} skuCode sku del producto a buscar
* @returns {Array}
*/
  const handleSubmitSku = (event) => {
    event.preventDefault();

    axios
      .get(
        process.env.REACT_APP_BACK_URL + `/api/admin/producto/get_producto_by_sku_update/${skuCode}`
      )
      .then((response) => {
        // setInitial(true);
        console.log(response);
        setProductName(response.data[0].nombre);
        setSku(response.data[0].sku);
        setData_descripcion(response.data[0].descripcion);
        setData_short(response.data[0].short);
        setBrand(response.data[0].marca_id);
        setPrice(response.data[0].precio);
        setDiscountPrice(response.data[0].precio_descuento);
        setStock(response.data[0].stock);
        setSlug(response.data[0].slug);
        setData_caracteritica(response.data[0].caracteristicas);
        setSelectedSize(response.data[0].talla_producto_id);
        setUpc(response.data[0].upc);
        setPn(response.data[0].pn);
        setOriginales(response.data[0].fotos);
        setSelectedCategoriaPadre(response.data[0].categoria_padre);
        setSelectedCategoriaHija(response.data[0].categorias_hija.categoria_hija_id)
        setNormalPrice(response.data[0].precio_tachado);
        catPadreO.current = response.data[0].categoria_padre;
        catHijaO.current = response.data[0].categorias_hija.categoria_hija_id

        $('#summernote').summernote('code', response.data[0].caracteristicas);
        $('#summernote2').summernote('code', response.data[0].descripcion);
        $('#summernote3').summernote('code', response.data[0].short);

        var sum = Object.values(response.data[0].fotos).length;

        for (let i = 0; i < sum; i++) {

          let img = document.getElementById("fotoMostrar" + (i + 1));
          img.src = process.env.REACT_APP_FRONT_URL + response.data[0].fotos[i].ruta;
        }



      })
      .catch((error) => {
        console.error(error);
        // Verifica si error.response.data.message existe, de lo contrario usa el mensaje por defecto
        const errorMessage = error?.response?.data?.message
          ? error.response.data.message + "-rojo"
          : "No se pudo agregar el producto, intente nuevamente-rojo";
        setMensaje(errorMessage);
        setTipo(2);

        setTimeout(() => {
          setMsg("");
          setTipo(0);
        }, 5000);
      });
  };

  const setProductName2 = (texto) => {
    setProductName(texto);

  };

  const setSlug2 = (texto) => {
    let vari = limpiaSlug(texto);
    setSlug(vari);
  };

  const limpiaSlug = (text) => {
    let tildes = ['á', 'é', 'í', 'ó', 'ú', 'Á', 'É', 'Í', 'Ó', 'Ú', 'ñ', 'Ñ'];
    let reemp = ['a', 'e', 'i', 'o', 'u', 'a', 'e', 'i', 'o', 'u', 'n', 'n'];
    let borrar = ['!', '?', '¿', '¡', '@', '.', ',', '"', "'", '$', '%', '&', '/', '(', ')', '=', '¡', '+', '*', '´', '¨', '{', '[', ']', ']', ';', ':', '_', '#', '°', '|', '}'];
    for (let i = 0; i < tildes.length; i++) {
      text = text.replaceAll(tildes[i], reemp[i]);
    }
    for (let i = 0; i < borrar.length; i++) {
      text = text.replaceAll(borrar[i], "");
    }
    text = text.replaceAll(" ", "-");
    text = text.toLowerCase();
    return text;
  };

  var imageUploadDiv = $('div.note-group-select-from-files');
  if (imageUploadDiv.length) {
    imageUploadDiv.remove();
  }

  return (

    <div>

      <Mensaje mensaje={mensaje} setMensaje={setMensaje} />

      <AdminSidebar />

      <div className="regular">
        <h1>
          Dreamtec - <span>Administrador de productos</span>
        </h1>

        <div className="shadow">
          <div className="row">
            <div className="col-12 text-start my-3">
              <h2>Editar Producto</h2>
            </div>

            <div className="col-12 mb-5">
              <div className="row">
                <form onSubmit={handleSubmitSku}>
                  <div className="row">
                    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 mb-4">
                      <label htmlFor="" className="fw-bold mx-1 form-label">
                        Sku del producto:&nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={skuCode}
                        onChange={handleInputChangeSku}
                        placeholder="Ingresa el SKU para editar el producto"
                        required
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                      <button
                        aria-label="Buscar Producto"
                        className="agregar"
                        type="submit"
                      >
                        <i className="fa-solid fa-magnifying-glass"></i>{" "}
                        &nbsp;Buscar producto
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="col-12">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                    <label className="fw-bold mx-1 form-label">
                      Nombre del producto: &nbsp;
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      placeholder="Ingresa nombre producto"
                      type="text"
                      value={productName}
                      required
                      onChange={(e) => setProductName2(e.target.value)}
                    />
                  </div>

                  <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                    <label className="fw-bold mx-1 form-label">
                      SKU: &nbsp;<span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      placeholder="Ingresa SKU"
                      value={sku}
                      onChange={(e) => setSku(e.target.value)}
                    />
                  </div>

                  <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                    <label className="fw-bold mx-1 form-label">
                      Categoria: &nbsp;<span className="text-danger">*</span>
                    </label>
                    <select
                      value={selectedCategoriaPadre}
                      onChange={(e) => setSelectedCategoriaPadre(e.target.value)}
                      className="form-control rounded-pill"
                      required
                    >
                      <option value="">Selecciona una categoria</option>
                      {categoriaPadre.map((categoria) => {
                        if (categoria.id > 0) {
                          return <option key={categoria.id} value={categoria.id}>
                            {categoria.categoria.charAt(0).toUpperCase() + categoria.categoria.slice(1)}
                          </option>
                        }
                      })}
                    </select>
                  </div>

                  <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                    <label className="fw-bold mx-1 form-label">
                      Categoria Hija: &nbsp;<span className="text-danger">*</span>
                    </label>
                    <select
                      value={selectedCategoriaHija}
                      onChange={(e) => setSelectedCategoriaHija(e.target.value)}
                      className="form-control rounded-pill"
                      required
                    >
                      <option value="">Selecciona una categoria hija</option>
                      {categoriaHija.map((categoria) => (
                        <option key={categoria.id} value={categoria.id}>
                          {categoria.hija.charAt(0).toUpperCase() + categoria.hija.slice(1)}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                    <label className="fw-bold mx-1 form-label">
                      Stock: &nbsp;<span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      required
                      placeholder="Ingresa Stock"
                      value={stock}
                      pattern="[0-9]{9}"
                      onChange={(e) => setStock(parseInt(e.target.value))}
                    />
                  </div>

                  <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                    <label className="fw-bold mx-1 form-label">
                      Slug: &nbsp;<span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      placeholder="Ingresa slug"
                      value={slug}
                      onChange={(e) => setSlug2(e.target.value)}
                    />
                  </div>

                  <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 mb-4">
                    <label className="fw-bold mx-1 form-label">
                      Características destacadas (máximo 1.000 caracteres): &nbsp;
                      <span className="text-danger">*</span>
                    </label>

                    <div id="summernote"></div>


                    {/* <button onClick={logC}>Log editor content</button> */}

                  </div>

                  <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 mb-4">
                    <label className="fw-bold mx-1 form-label">
                      Short (mini descripción, máximo 10.000 caracteres): &nbsp;
                      <span className="text-danger">*</span>
                    </label>

                    <div id="summernote3"></div>

                    {/* <button onClick={logS}>Log editor content</button> */}
                  </div>

                  <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 mb-4">
                    <label className="fw-bold mx-1 form-label">
                      Descripción (máximo 50.000 caracteres): &nbsp;
                      <span className="text-danger">*</span>
                    </label>

                    <div id="summernote2"></div>

                    {/* <button onClick={log}>Log editor content</button> */}
                  </div>

                  <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                    <label className="fw-bold mx-1 form-label">
                      Marca: &nbsp;<span className="text-danger">*</span>
                    </label>
                    <select
                      value={brand}
                      onChange={(e) => setBrand(e.target.value)}
                      className="rounded-pill form-control"
                      required
                    >
                      <option value="">Selecciona una marca</option>
                      {marcas?.map((marca) => (
                        <option key={marca.id} value={marca.id}>
                          {marca.nombre_mostrar}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                    <label className="fw-bold mx-1 form-label">
                      Talla del producto: &nbsp;
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      value={selectedSize}
                      onChange={(e) => setSelectedSize(e.target.value)}
                      className="form-control rounded-pill"
                      required
                    >
                      <option value="">Selecciona una talla</option>
                      {tallas?.map((talla) => (
                        <option key={talla.id} value={talla.id}>
                          {talla.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                    <label className="fw-bold mx-1 form-label">
                      Precio con descuento: &nbsp;
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      pattern="[0-9]{9}"
                      value={discountPrice}
                      onChange={(e) =>
                        setDiscountPrice(parseFloat(e.target.value))
                      }
                      placeholder="Ingresa precio descuento"
                      required
                    />
                  </div>

                  <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                    <label className="fw-bold mx-1 form-label">
                      Precio normal (tachado): &nbsp;
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="number"
                      pattern="[0-9]{9}"
                      value={normalPrice}
                      onChange={(e) =>
                        setNormalPrice(parseFloat(e.target.value))
                      }
                      placeholder="Ingresa precio normal"
                      required
                    />
                  </div>

                  <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                    <label className="fw-bold mx-1 form-label">
                      UPC: &nbsp;<span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      placeholder="Ingresa UPC"
                      type="text"
                      value={upc}
                      required
                      onChange={(e) => setUpc(e.target.value)}
                    />
                  </div>

                  <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                    <label className="fw-bold mx-1 form-label">
                      P/N: &nbsp;<span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      placeholder="Ingresa P/N"
                      value={pn}
                      onChange={(e) => setPn(e.target.value)}
                    />
                  </div>

                  <div className="row col-10">
                    <div className="row col-xxl-6 col-xl-6 col-md-12 col-sm-12 col-12 mb-3">
                      <div className="col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
                        <label>
                          Foto 1: &nbsp;<span className="text-danger">*</span>
                        </label>

                        <input
                          type="file"
                          id="foto1"
                          accept="image/*"
                          onChange={(e) =>
                            setFotosNuevas(1, e.target.files[0])
                          }
                          className="form-control col-12"
                        />

                      </div>
                      <div className="col-xxl-4 col-xl-4 col-md-12 col-sm-12 col-12 h-100 text-center">
                        <label htmlFor="fotoMostrar1" id="labelMostrar1">Foto Actual</label>
                        <img loading="lazy" src="" alt="" id="fotoMostrar1" className="container-fluid" accept="image/jpg, image/jpeg, image/png" />
                      </div>
                    </div>

                    <div className="row col-xxl-6 col-xl-6 col-md-12 col-sm-12 col-12 mb-3 ms-4">
                      <div className="col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
                        <label>
                          Foto 2:
                        </label>

                        <input
                          type="file"
                          id="foto2"
                          accept="image/*"
                          onChange={(e) =>
                            setFotosNuevas(2, e.target.files[0])
                          }
                          className="form-control col-12"
                        />

                      </div>
                      <div className="col-xxl-4 col-xl-4 col-md-12 col-sm-12 col-12 h-100 text-center">
                        <label htmlFor="fotoMostrar2" id="labelMostrar2">Foto Actual</label>
                        <img loading="lazy" src="" alt="" id="fotoMostrar2" className="container-fluid" accept="image/jpg, image/jpeg, image/png" />
                      </div>
                    </div>
                  </div>

                  <div className="row col-10">
                    <div className="row col-xxl-6 col-xl-6 col-md-12 col-sm-12 col-12 mb-3">
                      <div className="col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
                        <label>
                          Foto 3:
                        </label>

                        <input
                          type="file"
                          id="foto3"
                          accept="image/*"
                          onChange={(e) =>
                            setFotosNuevas(3, e.target.files[0])
                          }
                          className="form-control col-12"
                        />

                      </div>
                      <div className="col-xxl-4 col-xl-4 col-md-12 col-sm-12 col-12 h-100 text-center">
                        <label htmlFor="fotoMostrar3" id="labelMostrar3">Foto Actual</label>
                        <img loading="lazy" src="" alt="" id="fotoMostrar3" className="container-fluid" accept="image/jpg, image/jpeg, image/png" />
                      </div>
                    </div>

                    <div className="row col-xxl-6 col-xl-6 col-md-12 col-sm-12 col-12 mb-3 ms-4">
                      <div className="col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
                        <label>
                          Foto 4:
                        </label>

                        <input
                          type="file"
                          id="foto4"
                          accept="image/*"
                          onChange={(e) =>
                            setFotosNuevas(4, e.target.files[0])
                          }
                          className="form-control col-12"
                        />

                      </div>
                      <div className="col-xxl-4 col-xl-4 col-md-12 col-sm-12 col-12 h-100 text-center">
                        <label htmlFor="fotoMostrar4" id="labelMostrar4">Foto Actual</label>
                        <img loading="lazy" src="" alt="" id="fotoMostrar4" className="container-fluid" accept="image/jpg, image/jpeg, image/png" />
                      </div>
                    </div>
                  </div>

                  <div className="row col-10">
                    <div className="row col-xxl-6 col-xl-6 col-md-12 col-sm-12 col-12 mb-3">
                      <div className="col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
                        <label>
                          Foto 5:
                        </label>

                        <input
                          type="file"
                          id="foto5"
                          accept="image/*"
                          onChange={(e) =>
                            setFotosNuevas(5, e.target.files[0])
                          }
                          className="form-control col-12"
                        />

                      </div>
                      <div className="col-xxl-4 col-xl-4 col-md-12 col-sm-12 col-12 h-100 text-center">
                        <label htmlFor="fotoMostrar5" id="labelMostrar5">Foto Actual</label>
                        <img loading="lazy" src="" alt="" id="fotoMostrar5" className="container-fluid" accept="image/jpg, image/jpeg, image/png" />
                      </div>
                    </div>

                    <div className="row col-xxl-6 col-xl-6 col-md-12 col-sm-12 col-12 mb-3 ms-4">
                      <div className="col-xxl-8 col-xl-8 col-md-12 col-sm-12 col-12">
                        <label>
                          Foto 6:
                        </label>

                        <input
                          type="file"
                          id="foto6"
                          accept="image/*"
                          onChange={(e) =>
                            setFotosNuevas(6, e.target.files[0])
                          }
                          className="form-control col-12"
                        />

                      </div>
                      <div className="col-xxl-4 col-xl-4 col-md-12 col-sm-12 col-12 h-100 text-center">
                        <label htmlFor="fotoMostrar6" id="labelMostrar6">Foto Actual</label>
                        <img loading="lazy" src="" alt="" id="fotoMostrar6" className="container-fluid" accept="image/jpg, image/jpeg, image/png" />
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 mb-3">
                      <label className="mb-3 mt-2">
                        Mercado Libre:
                      </label>
                      <input
                        type="checkbox"
                        className="mx-2"
                        checked={check_mercado_libre}
                        onChange={handle_check_mercado_libre}
                      />
                    </div> */}



                  {/* <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 mb-3">
                      <label className="mb-3 mt-2">
                        Paris:
                      </label>
                      <input
                        type="checkbox"
                        className="mx-2"
                        checked={check_paris}
                        onChange={handle_check_paris}
                      />
                    </div> */}

                  {/* <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 mb-3">
                      <label className="mb-3 mt-2">
                        Ripley:
                      </label>
                      <input
                        type="checkbox"
                        className="mx-2"
                        checked={check_ripley}
                        onChange={handle_check_ripley}
                      />
                    </div> */}

                  {check_paris && (
                    <div className="row">

                      <div className="col-12">
                        <label className="mb-3 mt-2">Paris</label>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                        <label>
                          Familia: &nbsp;<span className="text-danger">*</span>
                        </label>
                        <select
                          value={selected_paris_familias}
                          onChange={(e) => setSelected_paris_familias(e.target.value)}
                          className="form-control rounded-pill"
                          required
                        >
                          <option value="">Selecciona una Familia</option>
                          {paris_familias?.map((familia) => (
                            <option key={familia.id} value={familia.id}>
                              {familia.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                        <label>
                          Categiorias: &nbsp;<span className="text-danger">*</span>
                        </label>
                        <select
                          value={selected_paris_categoria}
                          onChange={(e) => setSelected_paris_categoria(e.target.value)}
                          className="form-control rounded-pill"
                          key="select_caterorias"
                          required
                        >
                          <option value="">Selecciona una Categoria</option>
                          {paris_categorias?.map((categoria, index) => (
                            <option key={index + categoria.id} value={categoria.id}>
                              {categoria.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      {inputs_paris.length > 0 && (
                        <>
                          {mostrarInputs && (
                            <>
                              {inputs_paris.map((input, index) => (
                                <>
                                  {input.values.length === 0 ? (
                                    <div key={"div1_" + index} className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                      <label key={"label1_" + input.id}>
                                        {input.name}: &nbsp;<span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        key={"select1_" + index}
                                        name={input.id}
                                        placeholder={input.name}
                                        required
                                        onChange={(event) => handleInputChangeParis(index, event)} />
                                    </div>
                                  ) : (
                                    <div key={"div2_" + index} className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-4">
                                      <label key={"label2_" + index}>
                                        {input.name}: &nbsp;<span className="text-danger">*</span>
                                      </label>
                                      <select
                                        key={"select2_" + index}
                                        onChange={(event) => handleInputChangeParis(index, event)}
                                        className="form-control rounded-pill"
                                        required
                                      >
                                        <option key={"option_" + index} value="">Selecciona una opcion</option>
                                        {input.values?.map((opciones, index2) => (
                                          <>
                                            {opciones.id + "_" + input.type == input.value ? (
                                              <option key={"option1_" + index2 + "_" + opciones.id} value={opciones.id + "_" + input.type} selected >
                                                {opciones.name}
                                              </option>
                                            ) : (
                                              <option key={"option2_" + index2 + "_" + opciones.id} value={opciones.id + "_" + input.type}  >
                                                {opciones.name}
                                              </option>
                                            )}
                                          </>
                                        ))}
                                      </select>
                                    </div>
                                  )}
                                </>
                              ))}
                            </>
                          )}
                        </>
                      )}

                    </div>
                  )}


                  <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                    <button
                      aria-label="Editar Producto"
                      className="btnGreen w-100"
                      type="submit"
                    >
                      Editar Producto
                    </button>
                  </div>
                  <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 mb-4">
                    <button
                      aria-label="Agregar producto"
                      className="cancel w-100"
                      type="submit"
                      onClick={() => {
                        props.setAccion(1);
                      }}
                    >
                      Volver atrás
                    </button>
                  </div>
                </div>

                <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 my-3">
                  <span
                    className={
                      tipo == 1 ? "text-success fw-bold" : "text-danger fw-bold"
                    }
                  >
                    {msg}
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductUpdate;
