/**
 * Componente Simulator:
 * 
 * acá se el simulador de despacho en el detalle del producto
 * 
 * @module ComponenteSimulatorjsx
 */

import { useState, useEffect } from "react";
import "./simulator.css";

import axios from "axios";
import ReactGA from "react-ga4";

import { useDispatch, useSelector } from "react-redux";
import { setTipoEnvio } from "../../redux/tipoEnvioSlice";
import { setFechaEntrega } from "../../redux/fechaEntregaSlice";
import { setComunaSelected } from "../../redux/comunaSelectedSlice";
import { setRegionSelected } from "../../redux/regionSelectedSlice";
import { setAddToCart } from "../../redux/AddToCartSlice";
import { setCheckStock } from "../../redux/checkStockSlice";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";

const Simulator = (stock, sizeCateg) => {

  /**
* @constant
* @name isSimulated
* @type {boolean}
* 
* */
  const [isSimulated, setIsSimulated] = useState(false);


  /**
* @constant
* @name regiones
* @type {Array}
* 
* */
  const [regiones, setRegiones] = useState([]);


  /**
* @constant
* @name comunas
* @type {Array}
* 
* */
  const [comunas, setComunas] = useState([]);

  /**
* @constant
* @name selectedRegion
* @type {string}
* 
* */
  const [selectedRegion, setSelectedRegion] = useState("");

  /**
* @constant
* @name selectedComuna
* @type {string}
* 
* */
  const [selectedComuna, setSelectedComuna] = useState("");

  /**
* @constant
* @name options
* @type {Array}
* 
* */
  const [options, setOptions] = useState([]);


  /**
* @constant
* @name usuarioId
* @type {string}
* 
* */
  const [usuarioId, setUsuarioId] = useState("");


  /**
* @constant
* @name stockSelected
* @type {number}
* 
* */
  const [stockSelected, setStockSelected] = useState(1);

  /**
* @constant
* @name showAgregado
* @type {boolean}
* 
* */
  const [showAgregado, setShowAgregado] = useState(false);


  /**
* @constant
* @name userAlready
* @type {string}
* 
* */
  const [userAlready, setUserAlready] = useState(
    sessionStorage.getItem("IDusuario")
  );

  const [precioEnvio, setPrecioEnvio] = useState("");

  const navigate = useNavigate();

  const handleShowAgregado = () => setShowAgregado(true);
  const handleCloseAgregado = () => {
    setShowAgregado(false);
  }

  /**
* @function
* Petición HTTP para registrar el Id random del usuario
* @returns {string}
* 
*/
  const generateRandomId = () => {
    axios
      .get(process.env.REACT_APP_BACK_URL + "/api/usuario/register_usuario_id")
      .then((response) => {
        sessionStorage.setItem("IDusuario", response.data.id);
        return response.data.id;
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const sku = stock.sku.toString();
  const cantidad_carro = stock.cantidad;
  const dispatch = useDispatch();

  const fechaEntrega = useSelector((state) => state.fechaEntrega);
  const nombreComuna = useSelector((state) => state.comunaSelected);
  const nombreRegion = useSelector((state) => state.regionSelected);
  const tipoEnvio = useSelector((state) => state.tipoEnvio);
  const addToCart = useSelector((state) => state.addToCart);
  const checkStock = useSelector((state) => state.checkStock);
  const stockN = (stock.stock - cantidad_carro);



  useEffect(() => {
    if (userAlready == null)
      setUsuarioId(generateRandomId());

    if (tipoEnvio === 4) {
      setIsSimulated(false);
    }

    /**
* @function
* Petición HTTP para obtener todas las regiones
* @returns {string}
* 
*/
    axios
      .get(process.env.REACT_APP_BACK_URL + "/api/region/get_regiones")
      .then((response) => {
        setRegiones(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    /**
  * @function
  * Petición HTTP que trae la dirección ingresada por el usuario anteriormente, 
  * validando que inicio sesión y el tipo de envio sea distinto de 4 (Retiro en tienda)
  * @param {string} userAlready Id del usuario
  * @param {number} tipoEnvio Tipo de envio seleccionado anteriormente
  *@returns {Array}
  * 
  */
    if (userAlready && tipoEnvio !== 4) {
      var elSlugg = window.location.pathname.split("/")[1];
      axios
        .get(
          process.env.REACT_APP_BACK_URL + `/api/direccion/get_direccion/${userAlready}?slug=${elSlugg}`
        )
        .then((response) => {
          dispatch(setTipoEnvio(response.data.direcicon.codigo_envio));
          dispatch(setFechaEntrega(response.data.direcicon.fecha));
          dispatch(setComunaSelected(response.data.direcicon.nombre_comuna));
          dispatch(setRegionSelected(response.data.direcicon.nombre_region));
          setPrecioEnvio(response.data.direcicon.valor_envio);
          setIsSimulated(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [nombreRegion, fechaEntrega, tipoEnvio]);

  /**
* @function
* Petición HTTP que trae las comunas por región
* @param {string} comuna 
*/
  useEffect(() => {
    if (selectedRegion !== "") {
      axios
        .get(
          process.env.REACT_APP_BACK_URL + `/api/comuna/get_comunas_by_region/${selectedRegion}`
        )
        .then((response) => {
          setComunas(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setComunas([]);
    }
  }, [selectedRegion]);

  useEffect(() => {
    const newOptions = [];
    for (let i = 1; i <= stockN; i++) {
      newOptions.push(i);
    }
    setOptions(newOptions);
  }, [stockN]);

  /**
* @function
* Metodo se setea el useState de la región selecionada
* 
*/
  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
    setSelectedComuna("");
  };


  /**
* @function
* Metodo se setea el useState de la comuna selecionada
* 
*/
  const handleComunaChange = (event) => {
    setSelectedComuna(event.target.value);
  };

  /**
* @function
* Metodo que setea en vacio los useState correspondientes
* 
*/
  const handleNewAddress = () => {
    setIsSimulated(false);
    setSelectedComuna("");
    setSelectedRegion("");
    dispatch(setComunaSelected(""));
  };

  /**
* @function
* Petición HTTP que registra la dirección del usuario
* @param userAlready Id del usuario logueado
* @param selectedRegion Id de la región seleccionada
* @param selectedComuna Id de la comuna seleccionada
* @param elSlug Slug del producto
*@returns {Array}
* 
*/
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSimulated(true);

    var elSlug = window.location.pathname.split("/")[1];
    if (elSlug != null && elSlug != "") {
      axios
        .post(process.env.REACT_APP_BACK_URL + "/api/direccion/register_direccion", {
          usuario: userAlready ? userAlready : usuarioId,
          region: selectedRegion,
          comuna: selectedComuna,
          slug: elSlug,
        })
        .then((response) => {
          dispatch(setTipoEnvio(response.data.direccion.codigo_envio));
          dispatch(setFechaEntrega(response.data.direccion.fecha));
          dispatch(setComunaSelected(response.data.direccion.nombre_comuna));
          dispatch(setRegionSelected(response.data.direccion.nombre_region));
          setPrecioEnvio(response.data.direccion.valor_envio);
          setUserAlready(response.data.usuario.id);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else {
      axios
        .post(process.env.REACT_APP_BACK_URL + "/api/direccion/register_direccion", {
          usuario: userAlready ? userAlready : usuarioId,
          region: selectedRegion,
          comuna: selectedComuna,
        })
        .then((response) => {
          dispatch(setTipoEnvio(response.data.direccion.codigo_envio));
          dispatch(setFechaEntrega(response.data.direccion.fecha));
          dispatch(setComunaSelected(response.data.direccion.nombre_comuna));
          dispatch(setRegionSelected(response.data.direccion.nombre_region));
          setPrecioEnvio(response.data.direccion.valor_envio);
          setUserAlready(response.data.usuario.id);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleStockSelected = (event) => {
    setStockSelected(event.target.value);
  };

  const addToCartGa4 = (sku, cantidad) => {
    axios
      .get(process.env.REACT_APP_BACK_URL + `/api/ga/agregarproductoga4?sku=${sku}&cantidad=${cantidad}`)
      .then((response) => {
        console.log(response);
        ReactGA.gtag("event", "add_to_cart", {
          currency: "CLP",
          value: response.data.value,
          items: [{
            item_id: response.data.sku,
            item_name: response.data.nombre,
            item_brand: response.data.marca,
            item_category: response.data.categoria_padre,
            item_category2: response.data.categoria_hija,
            price: response.data.precio,
            quantity: response.data.cantidad
          }]
        });
      })
      .catch((error) => {
        console.log(error)
      })
  };

  /**
* @function
* Petición HTTP que registra dentro del carro un producto por su SKU
*@returns {Array}
* 
*/
  const handleAddToCart = () => {
    axios
      .post(
        process.env.REACT_APP_BACK_URL + "/api/carrocompras/register_carrocompras",
        {
          usuario: userAlready ? userAlready : usuarioId,
          sku: sku,
          cantidad: stockSelected,
        }
      )
      .then((response) => {
        sessionStorage.setItem("IDusuario", response.data.usuario);
        setUserAlready(sessionStorage.getItem("IDusuario"));
        dispatch(setAddToCart(addToCart + 1));
        dispatch(setCheckStock(response.data.productos[0].cantidad));
        mostrarAgregado();
        addToCartGa4(sku, stockSelected);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const mostrarAgregado = () => {
    handleShowAgregado();
  }

  /**
* @function
* Metodo que valida si existe el token Web y lo manda al paso 2 sino al paso 1
* 
*/
  const irAPagar = () => {
    if (sessionStorage.accessTokenWeb)
      navigate("/paso2");
    else
      navigate("/paso1");
  };

  return (
    <div>


      <Modal show={showAgregado} onHide={handleCloseAgregado} size="lg" centered={true}>
        <Modal.Header closeButton className="border-bottom-0">
        </Modal.Header>
        <Modal.Body>

          <div className="container ms-2 me-2" >
            {stock.producto && (
              <>
                <div className="row justify-content-center text-center col-12 mb-4">
                  <div className="col-md-12">
                    <label htmlFor="" className="fw-bold" style={{ color: "#00bfd6", fontSize: "26px" }}>Has agregado &nbsp;</label>
                    <img
                      src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/carrito/icono-boton-carro-agregar.png"}
                      alt="imagen carrito"
                      title="imagen carrito"
                      className="imgcarrito bg-azul text-white rounded-pill px-2 py-1 border-0 fs-14"
                      style={{ transform: "translate(0%, -15%)" }}
                      loading="lazy"
                    />{" "}
                  </div>
                </div>

                <div className="row justify-content-center align-self-center">
                  <div className="col-md-6">
                    <img src={process.env.REACT_APP_FRONT_URL + stock.producto.foto_1} alt={stock.producto.nombre} title={stock.producto.nombre} className="img-fluid" loading="lazy" />
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <label htmlFor="" style={{ fontSize: "18px" }} className="fw-bold col-md-8 mt-3">{stock.producto.nombre}</label>
                      <label htmlFor="" style={{ fontSize: "18px" }} className="col-md-4 mt-3">{"$" + stock.producto.precio.toLocaleString("es-CL")}</label>
                    </div>
                  </div>
                </div>

                <hr style={{ height: "1px" }} />

                <div className="row col-md-12">
                  <div className="col-md-6">
                    <button
                      aria-label="Verificar categoria"
                      className="btnCelesteDreamtec mt-3 px-3 col-md-6 col-xs-12 w-100"
                      type="submit"
                      onClick={handleCloseAgregado}
                    >
                      Seguir comprando
                    </button>
                  </div>
                  <div className="col-md-6 mb-4">
                    <button
                      aria-label="Verificar categoria"
                      className="btnGreen mt-3 px-3 col-md-6 col-xs-12 w-100"
                      type="submit"
                      onClick={() => { irAPagar() }}
                    >
                      Ir a pagar
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>

        </Modal.Body>
      </Modal>


      {stockN > 0 ? (
        <>

          <div className="row justify-content-center simuladorborder">
            <div className="col-12 px-3">
              <div className="row mb-3 mt-3">
                <div className="col-lg-12 col-md-12 col-12 text-start mb-2">
                  <p className="fs-6 text-turq mb-1 fw-bolder">Despacho</p>
                  <p className="small">
                    Ingresa región y comuna de despacho y podrás ver costos de
                    envíos y tiempos de entrega estimados.
                  </p>
                  {isSimulated ? (
                    <div>
                      <div className="div-gris-shadow p-3">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-12 fs-12 mb-3">
                            <img
                              src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/img/icono_flecha_abajo.png"}
                              className="img-fluid me-2"
                              alt="flecha"
                              title="flecha"
                              loading="lazy"

                            />
                            <span id="inner_comuna_single">{nombreComuna}, </span>

                            <span id="inner_region_single">{nombreRegion}</span>
                          </div>
                          <div className="col-lg-12 col-md-12 col-12 mb-3">
                            <img
                              src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/img/icono_calendario.png"}
                              className="img-fluid me-2"
                              alt="icono calendario"
                              title="icono calendario"
                              loading="lazy"
                            />
                            <span className="fs-12">
                              Tu pedido <strong>{fechaEntrega}</strong>
                              <span
                                className="fw-bold"
                                id="inner_fecha_despacho_single"
                              ></span>
                            </span>
                          </div>


                          {precioEnvio != "Envio gratis" && (
                            <>
                              <div
                                className="col-lg-12 col-md-12 col-12 mb-3"
                                id="inner_valor_despacho_single"
                              >
                                <img
                                  src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/img/icono_valor.png"}
                                  className="img-fluid me-2"
                                  alt="icono precio"
                                  title="icono precio"
                                  loading="lazy"
                                />
                                <span className="fs-12">
                                  Valor del despacho: <strong>{precioEnvio.toLocaleString("es-CL", {
                                    style: "currency",
                                    currency: "CLP",
                                  }) + " CLP"}</strong>
                                </span>
                              </div>
                            </>
                          )}
                          {precioEnvio == "Envio gratis" && (
                            <div
                              className="col-lg-12 col-md-12 col-12 mb-3 justify-content-center text-center"
                              id="inner_valor_despacho_single"
                            >
                              <img src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/despachogratis.png"} alt="despacho gratis" title="despacho gratis" className="img-fluid" loading="lazy" />
                            </div>
                          )}
                        </div>
                      </div>{" "}
                      <div className="col-lg-12 col-md-12 col-12 text-end mt-3">
                        <button
                          aria-label="Ingresar otra dirección"
                          id="btn_ingresar_otra_direccion"
                          type="button"
                          onClick={handleNewAddress}
                          className="btn fs-10 rounded-pill bg-azul-black btn-azul w-100 py-1 text-white"
                        >
                          Ingresar otra dirección
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="p-3">
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-12 mb-3">
                          <label
                            htmlFor="inputState"
                            className="fw-bold form-label"
                          >
                            Región <span className="text-turq">*</span>
                          </label>
                          <select
                            className="form-select rounded-pill form-select-sm"
                            aria-label=".form-select-sm example"
                            onChange={handleRegionChange}
                          >
                            <option>Selecciona una región</option>
                            {regiones.map((region) => (
                              <option key={region.id} value={region.id}>
                                {region.nombre_region}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-lg-12 col-md-12 col-12 mb-3">
                          <label
                            htmlFor="inputState"
                            className="fw-bold form-label"
                          >
                            Comuna <span className="text-turq">*</span>
                          </label>
                          <select
                            className="form-select rounded-pill form-select-sm"
                            aria-label=".form-select-sm example"
                            value={selectedComuna}
                            onChange={handleComunaChange}
                          >
                            <option>Selecciona una comuna</option>
                            {comunas.map((comuna) => (
                              <option key={comuna.id} value={comuna.id}>
                                {comuna.nombre_comuna}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-lg-12 col-md-12 col-12 mb-3">
                          <button
                            className="rounded-pill btn fs-16 w-100 py-1 text-white bg-turq btn-turq-hover"
                            onClick={handleSubmit}
                            disabled={selectedComuna === ""}
                          >
                            Consultar despacho
                          </button>
                        </div>
                        <div
                          className="row align-items-start justify-content-center mt-3"
                          id="div-retiro-inmediato"
                        >
                          <div className="div-gris-shadow px-3 py-2">
                            <div className="row">
                              <div className="col-lg-5 col-md-5 col-5 text-start d-flex px-0">
                                <img
                                  loading="lazy"
                                  src={process.env.REACT_APP_FRONT_URL + "/uploads/assets/img/icono_flecha_abajo.png"}
                                  className=""
                                  alt="Envío de producto"
                                  title="Envío de producto"
                                  width={10.5}
                                  height={12}
                                  style={{ height: '12px' }}
                                />
                                <span className="fs-12 text-dark fw-bolder">
                                  Retiro en tienda
                                </span>
                              </div>
                              <div className="col-lg-7 col-md-7 col-7 text-start px-0">
                                <p className="fs-12 text-black mb-1">
                                  Disponible para retiro en tienda
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className=" mb-5 col-lg-12 col-md-7 col-sm-11 col-11 text-center my-2 d-grid gap-2 px-3">
              <div className="row px-4 mb-3 justify-content-center">
                <span className="col-6">
                  Stock <b>{stockN}</b>
                </span>
                <select
                  className="col-6 rounded-pill"
                  onChange={handleStockSelected}
                >
                  {options.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              <button
                type="button"
                id="add-to-cart"
                className="border-0 bg-turq text-white fs-16 py-1 rounded-pill btn-turq-hover btn-sombra"
                aria-label="Ver carro de compra"
                onClick={handleAddToCart}
                disabled={stockN === 0 || stockN === cantidad_carro}
              >
                Agregar al carro
              </button>
            </div>
          </div>

        </>
      ) : (
        <>

          <div className="container">
            <div className="row mb-3 justify-content-center">

              <div className="col-12 text-center">
                <i className="fa-solid fa-ban rojo fs-40"></i>
              </div>

              <div className="col-12 text-center fw-bold fs-20-p">
                Producto sin stock
              </div>

            </div>
            <div className="row mb-3 text-center">

              <a href="/contacto">
                <button
                  type="button" aria-label="Contáctanos"
                  className="bg-turq text-white fs-6 px-5 py-1 rounded-pill border-0 btn-turq-hover btn-sombra w-100"
                >
                  Solicitar Cotización
                </button>

              </a>
            </div>
          </div>
        </>
      )}

    </div>
  );
};

export default Simulator;
